import React from 'react';
import { useLocation } from '@reach/router';

import useUserLangOrDefault from '../../hooks/useUserLangOrDefault';
import PrivacyPolicyEn from './index.en';
import PrivacyPolicyPt from './index.pt';

const PrivacyPolicyPage = () => {
  const lang = useUserLangOrDefault();
  const { pathname } = useLocation();

  if (pathname.startsWith('/en')) return <PrivacyPolicyEn />;
  if (pathname.startsWith('/pt')) return <PrivacyPolicyPt />;

  if (lang === 'en') return <PrivacyPolicyEn />;

  return <PrivacyPolicyPt />;
};

export default PrivacyPolicyPage;
